import React, { Component } from 'react';
import Page from '../containers/page';
import {
    Col,
    Container, Row,

} from 'reactstrap';
import image from '../assets/images/account-cover.png';
import imageMobile from '../assets/images/account-cover.png';
import {getSearchParams} from "../helpers/linkHelper";
import {API_ENDPOINT} from "../constants";

class BriqpayPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            clientToken: null,
            status: null,
            sessionId: null
        };
    }
    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
            ).then((data) => {
                this.setState({
                    ...data,
                });
            });
        }

        let params = getSearchParams(this.props[0].location.search, {});

        if (this.props.loadDataWithQuery) {
            for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
                this.props.loadDataWithQuery[i](
                    window.fetch,
                    this.props.lang,
                    this.props[0].match,
                    params,
                    this.props[0].location.pathname,
                ).then((data) => {
                    console.log("Momo", data)
                    localStorage.setItem('brigPaySession', data.sessionId);
                    this.setState(
                        {
                            ...data,
                            loading: null,
                        },
                        () => {
                            this.props.updateMeta(
                                this.props.generateSeoTags(this.state, this.props.lang),
                            );
                        },
                    );
                });
            }
        }

        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang),
        );
    };
    async componentDidMount() {
        await this.get(); // Assuming this is some async function you have

        setTimeout(() => {
             this.setupBriqpayExample()
        }, 50000)

    }
    componentWillUnmount() {
        this.unsubscribeEvents();
    }
    unsubscribeEvents = () => {
        console.log('unsubscribeEvents')
        if(window._briqpay) {
            console.log('unsubscribeEvents 2')
            window._briqpay.unsubscribe('before-purchase');
        }
    }
    subscribeToEvents = () => {
        console.log('subscribeToEvents')
        window._briqpay.subscribe('before-purchase', (data) => {
            if(data && data.sessionid) {
                this.makeDecision(data.sessionid, 'allow')
            }

        });

    }
    setupBriqpayExample = async () => {
        await  window._briqpay.v3.onReady(() => {
            console.log('BRIQPAY READY!')
        });
        this.subscribeToEvents();
    }
     makeDecision = (sessionId, decision) => {
         fetch(API_ENDPOINT + `/briqpay/make-decision/${sessionId}`, {
             method: 'POST',
             headers: {
                 'Content-Type': 'application/json',
             },
             body: JSON.stringify({
                 decision
             }),
         })
             .then((res) => res.json())

    }
    saveBrigPayClient = (sessionId) => {
        fetch(API_ENDPOINT + `/briqpay/client-session-save/${sessionId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => res.json())
    }
    render() {
        const iframeSrc = `https://msc-playground.briqpay.com?sessionToken=${this.state.clientToken}`;
        return (
            <div className="account-page">
                <section className="test">
                    <img
                        src={
                            typeof window !== 'undefined' && window.innerWidth < 768
                                ? imageMobile
                                : image
                        }
                    />
                    <div className="test-content">
                        <h1>{'BLI FÖRETAGSKUND'.translate(this.props.lang)}</h1>
                    </div>
                </section>

                <Container>
                    <Row style={{justifyContent: 'center', padding: '30px 0'}}>
                        <Col lg="12">
                            <div
                                className="account-container"
                                style={{textAlign: 'center'}}
                            >
                                <h3 style={{fontSize: 24, marginBottom: 10}}>
                                    {'SÅ HÄR GÅR DET TILL ATT HANDLA SOM FÖRETAG'.translate(this.props.lang)}
                                </h3>
                                <p style={{fontSize: 14}}>
                                    {'Bli företagskund och handla direkt från Venture Design! Registreringen är enkel – fyll i företagets\n' +
                                        'uppgifter nedan och få inloggningsuppgifter skickade till vald mejl. När ni har loggat in är det bara att\n' +
                                        'börja shoppa!\n' +
                                        'Betala smidigt med företagskort eller ansök om fakturabetalning – ni får svar direkt om det är möjligt\n' +
                                        'att handla på faktura. Signering sker säkert med Bank-ID.'.translate(
                                            this.props.lang,
                                        )}
                                </p>
                            </div>
                        </Col>

                    </Row>
                </Container>
                            <Container>
                    <Row className="justify-content-center">
                        <Col lg="6">
                            <div className="briqpay-container">
                                <h3>
                                    {'Företagsregistrering'.translate(this.props.lang)}
                                </h3>
                                      <div id="briqpay-container" className="klarna-confirmation-iframe">
                                    <iframe id="briqpay-iframe" title="Checkout" scrolling="no" allow="payment"
                                            src={iframeSrc}>
                                    </iframe>
                                </div>

                            </div>
                        </Col>
                    </Row>
                            </Container>
            </div>
        );
    }
}

export default Page(BriqpayPage);
